import { format } from 'date-fns';
// TODO: Deprecate moment and luxon;
import * as moment from 'moment';
import { DateTime } from 'luxon';
import React from 'react';

export const timePickerFormat = 'HH:mm';

export const formatPhoneNumber = (phoneNumber) => {
  if (phoneNumber) {
    const cleaned = phoneNumber.replace(/\D/g, '');
    let match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
    if (match) {
      return '(' + match[1] + ') ' + match[2] + '-' + match[3];
    }
    match = cleaned.match(/^(\d{3})(\d{3})(\d{3})(\d{4})$/);
    if (match) {
      return (
        '+' + match[1] + ' (' + match[2] + ') ' + match[3] + '-' + match[4]
      );
    }
  }
  return phoneNumber;
};

const fixDateStringFormat = (dateTime) =>
  dateTime.endsWith('Z') ||
  // Date already with time zone set.
  dateTime.match(/[+-]\d{2}:\d{2}$/) ||
  // Date without time.
  dateTime.match(/\d{4}-\d{2}-\d{2}T00:00:00$/)
    ? dateTime
    : `${dateTime}Z`;

export const formatDateTime = (
  dateTime,
  expression = dateFormats.withDayOfWeek
) => {
  if (!dateTime) return '';

  if (dateTime?.format) return dateTime.format(expression);

  if (typeof dateTime === 'string') {
    dateTime = new Date(fixDateStringFormat(dateTime));
  }

  try {
    return format(dateTime, expression);
  } catch (error) {
    console.error('Failed to format date', {
      errorMessage: error.message,
      dateTime,
      expression,
    });

    return `[${error.message}]`;
  }
};

export const differenceInDays = (format, startDate, endDate = moment()) => {
  if (!startDate || !endDate) return 0; // Return 0 if either date is missing
  const start = moment(startDate);
  const end = moment(endDate);
  return end.diff(start, format);
};

export const dateFormats = {
  year: 'yyyy',
  date: 'M/d/yyyy',
  withDayOfWeek: 'M/d/yyyy (dddd)',
  short: 'M/d/yy',
  shortWithTimeNoWeekday: 'M/d/yy h:mm a',
  payloadDate: 'M/d/y h:mm:ss a',
};

export const keepDigits = (input) => {
  if (input) {
    input = input.replace(/\D/g, '');
  }
  return input;
};

export const cropText = (text, maxTextLength) => {
  return text.length > maxTextLength
    ? text.substring(0, maxTextLength).trim() + '...'
    : text;
};

export const parseTimeSpanToMoment = (timeSpan) => {
  return (
    (timeSpan &&
      moment.parseZone(`${moment().format('YYYY-MM-DD')}T${timeSpan}`)) ||
    undefined
  );
};

export const parseDateToMoment = (date) => {
  return (date && moment.parseZone(date)) || undefined;
};

export const formatDate = (date) => {
  return date.format('YYYY-MM-DDTHH:mm:ss');
};

export const formatTimeSpan = (time) => {
  return (time && time.format('HH:mm:ss')) || undefined;
};

export const firstCharactersToUpperCase = (words) =>
  words.replace(/(^\w{1})|(\s+\w{1})/g, (letter) => letter.toUpperCase());

export const getCurrentDateString = () => new Date().toISOString();

export const calculateFreightClassByVolume = (
  volumeCubicInch,
  weightPound,
  freightClassOptions
) => {
  if (freightClassOptions === undefined) {
    return undefined;
  }
  let volumeInCubicFeet = volumeCubicInch / 1728.0;
  let freightClass;
  if (volumeInCubicFeet > 0) {
    let densityInPoundPerCubicFeet = weightPound / volumeInCubicFeet;
    let freightClassInUse = freightClassOptions.filter(
      (fco) =>
        fco.StartRange !== undefined &&
        fco.EndRange !== undefined &&
        densityInPoundPerCubicFeet >= fco.StartRange &&
        densityInPoundPerCubicFeet < fco.EndRange
    )[0];
    if (freightClassInUse && freightClassInUse.ClassDescription) {
      freightClass = parseFloat(freightClassInUse.ClassDescription);
    }
  }
  return freightClass;
};

export const calculateFreightClass = (
  lengthInch,
  widthInch,
  heightInch,
  weightPound,
  freightClassOptions
) => {
  return calculateFreightClassByVolume(
    lengthInch * widthInch * heightInch,
    weightPound,
    freightClassOptions
  );
};

export const convertToLowerQueryString = (object) => {
  return JSON.parse(JSON.stringify(object), function (prop, value) {
    var lower = prop.toLowerCase();
    if (prop === lower) return value;
    else this[lower] = value;
  });
};

export const getBase64DataType = (dataUri) => {
  return dataUri.substring('data:'.length, dataUri.indexOf(';base64'));
};

export const groupBy = (array, key) => {
  const grouped = array.reduce((result, currentValue) => {
    const copiedValue = { ...currentValue };

    const keyName = currentValue[key];

    let keyResult = result.filter((r) => r.key === keyName)[0];

    if (!keyResult) {
      keyResult = { key: keyName, values: [] };
      result.push(keyResult);
    }

    keyResult.values.push(copiedValue);

    return result;
  }, []);

  return grouped;
};

export const distinctBy = (array, by) => {
  const matchByJson = (element, current) =>
    `${JSON.stringify(element)}`.toLowerCase() ===
    `${JSON.stringify(current)}`.toLowerCase();

  const matchByFields = (element, current) => {
    for (const k of Object.keys(by)) {
      if (element[k] !== current[k]) {
        return false;
      }
    }
    return true;
  };

  const matches =
    (by && Object.keys(by).length > 0 && matchByFields) || matchByJson;

  return array?.reduce((elements, current) => {
    if (!elements.some((element) => matches(element, current))) {
      elements.push(current);
    }

    return elements;
  }, []);
};

export const convertInputToNonNegativeNumber = (
  inputValue,
  decimalPlaces = 0
) =>
  convertInputToNumber({
    inputValue,
    decimalPlaces,
    allowNegative: false,
  });

export const convertInputToNumber = ({
  inputValue,
  decimalPlaces = 0,
  allowNegative = true,
}) => {
  if (
    isNaN(inputValue) ||
    inputValue[inputValue.length - 1] === '.' ||
    inputValue === ''
  ) {
    return keepNumericCharacters(inputValue);
  }

  const roundedValue = roundNumber(inputValue, decimalPlaces);

  if (allowNegative) {
    return isNaN(roundedValue) ? '' : roundedValue;
  }

  return roundedValue >= 0 ? roundedValue : roundedValue < 0 ? 0 : '';
};

const keepNumericCharacters = (input) => {
  if (input) {
    input = input.replace(/[^\d.-]/g, '');
  }
  return input;
};

export const roundNumber = (number, roundDecimal = 2) =>
  +(+number).toFixed(roundDecimal);

export const printRoundedNumber = (
  number,
  roundDecimal = 2,
  isCurrency = false
) => {
  if (isNaN(number)) return '0';

  if (isCurrency && number < 0)
    return `(${roundNumber(Math.abs(number), roundDecimal).toLocaleString(
      'en-US',
      {
        minimumFractionDigits: roundDecimal,
      }
    )})`;

  return roundNumber(number, roundDecimal).toLocaleString('en-US', {
    minimumFractionDigits: roundDecimal,
  });
};

export const getUserEmail = () => localStorage.getItem('userEmail');

export const getUsername = () => localStorage.getItem('username');

export const sortDate = (a, b) => {
  if (a && b) {
    return new Date(a) - new Date(b);
  }
  if (a) return 1;
  if (b) return -1;
  return 0;
};

export const fromUtcDateStringToDate = (dateString) => {
  if (!dateString) return null;

  return new Date(fixDateStringFormat(dateString));
};

export const fromPayloadToLuxonDateTime = (payloadDateTime) =>
  (payloadDateTime &&
    DateTime.fromFormat(payloadDateTime, dateFormats.payloadDate)) ||
  null;

export const fromLuxonToPayloadDateTime = (luxonDateTime) =>
  (luxonDateTime &&
    luxonDateTime.isLuxonDateTime &&
    luxonDateTime.toFormat(dateFormats.payloadDate)) ||
  null;

export const getCurrentLuxonDateTime = () => DateTime.now();

export const fromJsToLuxonDateTime = (payloadDateTime) =>
  (payloadDateTime && DateTime.fromJSDate(payloadDateTime)) || null;

export const fromLuxonToJsDateTime = (luxonDateTime) =>
  (luxonDateTime &&
    luxonDateTime.isLuxonDateTime &&
    luxonDateTime.toJSDate()) ||
  null;

export const getEnumValue = (e, label) =>
  Object.keys(e).filter((k) => e[k] === label)[0];

export const getOptionsFromEnum = (e) =>
  Object.keys(e).map((k) => ({
    value: k,
    label: e[k],
    [k]: e[k],
  }));

export const bufferToBase64 = (buffer) => {
  return window.btoa(
    [].slice
      .call(new Uint8Array(buffer))
      .map(function (bin) {
        return String.fromCharCode(bin);
      })
      .join('')
  );
};

export const toFileObject = async (file) => {
  const readFile = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = () => {
        resolve(reader.result);
      };
      reader.onerror = reject;
      reader.readAsArrayBuffer(file);
    });
  };

  const content = bufferToBase64(await readFile(file));

  return {
    content: content,
    filename: file.name,
    type: file.type,
    size: file.size,
  };
};

export const downloadFileAsBase64Content = async (url) => {
  const buffer = await (await fetch(url)).arrayBuffer();
  const content = bufferToBase64(buffer);
  return content;
};

// https://danielbachhuber.com/2019/02/04/javascript-number-fraction/
export const numberToFraction = (amount) => {
  if (Number.isNaN(amount)) {
    return '';
  }

  // This is a whole number and doesn't need modification.
  if (parseFloat(amount) === parseInt(amount, 10)) {
    return amount;
  }
  // Next 12 lines are cribbed from https://stackoverflow.com/a/23575406.
  const gcd = function (a, b) {
    if (b < 0.0000001) {
      return a;
    }
    return gcd(b, Math.floor(a % b));
  };
  const len = amount.toString().length - 2;
  let denominator = 10 ** len;
  let numerator = amount * denominator;
  const divisor = gcd(numerator, denominator);
  numerator /= divisor;
  denominator /= divisor;
  let base = 0;
  // In a scenario like 3/2, convert to 1 1/2
  // by pulling out the base number and reducing the numerator.
  if (numerator > denominator) {
    base = Math.floor(numerator / denominator);
    numerator -= base * denominator;
  }
  amount = `${Math.floor(numerator)}/${Math.floor(denominator)}`;
  if (base) {
    amount = `${base}-${amount}`;
  }
  return amount;
};

export const printVariableName = (variableName) =>
  variableName
    ?.replace(/([A-Z])/g, ' $1')
    .replace(/^./, (str) => str.toUpperCase());

export const separator = ' • ';

export const joinString = (tokens, defaultSeparator = separator) =>
  tokens
    .map((t) => `${t || ''}`.trim())
    .filter((t) => t)
    .join(defaultSeparator);

export const renderTextLines = (text = '') =>
  text?.split('\n').map((l, i) => (
    <React.Fragment key={i}>
      {l}
      <br />
    </React.Fragment>
  )) || false;

export const isDateValid = (date) => {
  return date > new Date('1900-01-01');
};
