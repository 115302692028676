import { useState, useEffect, useContext } from 'react';
import {
  MsalAuthenticationTemplate,
  useMsal,
  useAccount,
} from '@azure/msal-react';
import { InteractionStatus, InteractionType } from '@azure/msal-browser';
import { loginRequest } from '../helpers/auth-config';
import { callMsGraph, getPhoto, getGroups } from '../helpers/ms-graph-api';
import UserContext from '../context/user-context';
import { EmployeeAPI } from '../api/employee';
import { Layout } from 'antd';
import styled from 'styled-components';
import 'antd/dist/antd.css';
import { dateFormats, formatDateTime } from '../shared/utils';
import { P21UserProvider } from '../context/p21-user-context';
import { HeaderMenu } from './header-menu';

const { Header, Content, Footer } = Layout;

const SiteLayout = ({ children }) => {
  const { instance, accounts, inProgress } = useMsal();
  const account = useAccount(accounts[0] || {});
  const { dispatch } = useContext(UserContext);

  const [graphData, setGraphData] = useState(null);
  const [profilePic, setProfilePic] = useState('');

  const [graphDataLoaded, setGraphDataLoaded] = useState(false);

  useEffect(() => {
    if (
      /*!graphData &&*/ !graphDataLoaded &&
      account &&
      inProgress !== InteractionStatus.Startup
    ) {
      requestProfileData();
    }
  }, [account, inProgress]);

  const requestProfileData = async () => {
    setGraphDataLoaded(true);
    try {
      const response = await instance.acquireTokenSilent({
        ...loginRequest,
        account: account,
      });

      const {
        preferred_username: userEmail,
        name,
        oid: objectId,
      } = response?.idTokenClaims || {};

      const graphResponse = await callMsGraph(response.accessToken);
      setGraphData(graphResponse);

      const groupResponse = await getGroups(response.accessToken);
      dispatch({
        type: 'SET_GROUPS',
        payload: {
          groups: groupResponse?.value || [],
          user: {
            emailAddress: userEmail?.toLowerCase(),
            name: name,
            objectId: objectId,
          },
        },
      });

      const photoResponse = await getPhoto(response.accessToken);
      const blob = await photoResponse.blob();
      const imageUrl = URL.createObjectURL(blob);
      setProfilePic(imageUrl);

      if (userEmail) {
        localStorage.setItem('userEmail', `${userEmail}`.toLowerCase());
        localStorage.setItem('username', `${name}`);

        await EmployeeAPI.getEmployee(userEmail, dispatch);
        await EmployeeAPI.getSamSession();
      }
    } catch (err) {
      console.error(err);
    }
  };

  const ErrorComponent = ({ error }) => {
    return <div>An Error Occurred: {error.errorCode}</div>;
  };

  const Loading = () => {
    return <div>Authentication in progress...</div>;
  };

  const authRequest = {
    ...loginRequest,
  };

  return (
    <MsalAuthenticationTemplate
      interactionType={InteractionType.Redirect}
      authenticationRequest={authRequest}
      errorComponent={ErrorComponent}
      loadingComponent={Loading}
    >
      <P21UserProvider graphData={graphData}>
        <Layout className='layout'>
          <Header>
            <HeaderMenu graphData={graphData} />
          </Header>
        </Layout>
        <Content
          style={{
            padding: '0',
            minHeight: '280px',
            background: '#fff',
          }}
        >
          <Margin>{children}</Margin>
        </Content>
        <Footer style={{ textAlign: 'center' }}>
          Container and Packaging ©
          {formatDateTime(new Date(), dateFormats.year)}
          {process.env.NEXT_PUBLIC_BUILD_ID
            ? ` - Build [${process.env.NEXT_PUBLIC_BUILD_ID}]`
            : ''}
        </Footer>
      </P21UserProvider>
    </MsalAuthenticationTemplate>
  );
};

export default SiteLayout;

const Margin = styled.div`
  margin: 20px;
`;
